import Btn from '@/component/button/btn/btn';
import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Input_text from '@/component/input/input_text/input_text';
import Tile from '@/component/tile/tile';
import { Context_auth } from '@/context/context_auth';
import { N_login_method } from '@/declaration/rds/model/type/n_login_method';
import { N_type_vcode } from '@/declaration/rds/model/type/n_type_vcode';
import { N_vcode_purpose } from '@/declaration/rds/model/type/n_vcode_purpose';
import { T_ccode } from '@/declaration/type/t_ccode';
import { T_form } from '@/declaration/type/t_form';
import { T_los_form } from '@/pages/auth/login';
import { $api } from '@/service/$api';
import { radio } from '@/state/radio';
import { loading } from '@/utility/dynamic/loading';
import LoginIcon from '@mui/icons-material/Login';
import { FormControl, FormControlLabel, InputLabel, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { FC, memo, useCallback, useContext, useEffect } from 'react';
import { usePrevious, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { useImmer } from 'use-immer';
import Vcode_button from '../../../antibot/vcode_button/vcode_button';
import Ccode_selector from '../../../ccode_selector/ccode_selector';

const methods = [N_login_method.id_like, N_login_method.phone, N_login_method.los] as const;
const Signup_agreement = dynamic(() => import('@/component/auth/signup_agreement/signup_agreement'), { loading });

export interface I_Form_login extends T_form<T_los_form> {
  test?: boolean;
}

const Form_login: FC<I_Form_login> = memo<I_Form_login>(
  ({ on_error, test, on_success, on_change, text_heading, text_submit, hide_heading, className, merge, ...rest }) => {
    const { t } = useTranslation();
    const { signer } = useContext(Context_auth);
    const [form, set_form] = useImmer<T_los_form>({
      ...merge,
      method: merge?.method || N_login_method.id_like,
    });
    const { phone, ccode } = form;
    const [pending, pending_toggle] = useToggle(false);
    const merge_prev = usePrevious(merge);

    const on_ccode_change = useCallback(
      (v: T_ccode | undefined): void => {
        set_form((s) => {
          s.ccode = v?.ccode;
        });
      },
      [set_form],
    );

    const submit = useCallback(
      async (e?: any): Promise<void> => {
        e?.preventDefault();
        if (pending) {
          return;
        }
        let action = 'login';
        const args: T_los_form = {
          __dev_no_vcode: !!test ?? undefined,
        };

        switch (form.method) {
          case N_login_method.los:
            action = 'los';
            args.method = 'sms';
            args.ccode = form.ccode;
            args.phone = form.phone;
            args.vcode = form.vcode;
            break;
          case N_login_method.id_like:
            args.password = form.password;
            if (form.id_like?.includes('@')) {
              args.email = form.id_like;
            } else {
              args.unique = form.id_like;
            }
            break;
          case N_login_method.phone:
            args.phone = form.phone;
            args.ccode = form.ccode;
            args.password = form.password;
        }

        try {
          pending_toggle(true);
          // use "self" server to login
          const bag = (await $api.call(`account/${action}`, args)) || {};
          if (bag) {
            radio.authorization.next(bag);
          }

          if (on_success) {
            on_success(bag);
          } else {
            // await push(query.get(N_lkey.sign_redirect) ?? config_auth.login_redirect);
          }
        } catch (e: any) {
          if (on_error) {
            on_error(e);
          } else {
            throw e;
          }
        } finally {
          pending_toggle(false);
        }
      },
      [
        pending,
        test,
        form.method,
        form.ccode,
        form.phone,
        form.vcode,
        form.password,
        form.id_like,
        pending_toggle,
        on_success,
        on_error,
      ],
    );

    const part = useCallback((): JSX.Element => {
      if (!form.method) {
        return <Tile>Please select a method to login ↑</Tile>;
      }

      switch (form.method) {
        case N_login_method.id_like:
          return (
            <div className="c gap-2">
              <div>
                <Input_text
                  onChange={(e) =>
                    set_form((s) => {
                      s.id_like = e.target.value;
                    })
                  }
                  value={form.id_like}
                  label={t('account:email') + '/' + t('account:username')}
                  id="id_like"
                  testid="input_id_like"
                  autoFocus
                  fullWidth
                />
              </div>
              <div>
                <Input_text
                  onChange={(e) =>
                    set_form((s) => {
                      s.password = e.target.value;
                    })
                  }
                  value={form.password}
                  label={t('account:password')}
                  type="password"
                  id="password"
                  testid="input_password"
                  fullWidth
                />
              </div>
            </div>
          );
        case N_login_method.phone:
          return (
            <>
              <div className="flex gap-2">
                <div className="w-40 flex-none">
                  <Ccode_selector on_change={on_ccode_change} />
                </div>
                <div className="flex-auto">
                  <Input_text
                    type="number"
                    className="w-full"
                    onChange={(e) =>
                      set_form((s) => {
                        s.phone = e.target.value;
                      })
                    }
                    value={form.phone}
                    label={t('account:phone')}
                    id="phone"
                    testid="input_phone"
                    autoFocus
                  />
                </div>
              </div>
              <div>
                <Input_text
                  onChange={(e) =>
                    set_form((s) => {
                      s.password = e.target.value;
                    })
                  }
                  value={form.password}
                  label={t('account:password')}
                  type="password"
                  id="password"
                  testid="input_password"
                  fullWidth
                />
              </div>
            </>
          );
        case N_login_method.los:
          return (
            <>
              <div className="r gap-2">
                <div className="w-40 flex-none">
                  <Ccode_selector on_change={on_ccode_change} />
                </div>
                <div className="flex-auto">
                  <Input_text
                    type="number"
                    className="w-full"
                    onChange={(e) =>
                      set_form((s) => {
                        s.phone = e.target.value;
                      })
                    }
                    value={form.phone}
                    label={t('account:phone')}
                    id="phone"
                    testid="input_phone"
                    fullWidth
                    autoFocus
                  />
                </div>
              </div>
              <div>
                <FormControl className="w-full" variant="outlined">
                  <InputLabel className="capitalize" htmlFor="vcode">
                    {t('account:vcode')}
                  </InputLabel>
                  <OutlinedInput
                    onChange={(e) =>
                      set_form((s) => {
                        s.vcode = e.target.value;
                      })
                    }
                    value={form.vcode}
                    label={t('account:vcode')}
                    id="vcode"
                    inputProps={{ 'data-testid': 'input_vcode' }}
                    endAdornment={
                      <Vcode_button
                        args={{
                          method: N_type_vcode.sms,
                          purpose: N_vcode_purpose.los,
                          phone,
                          ccode,
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
            </>
          );
        default:
          return (
            <>
              <div>Invalid login type: &quot;{form.method}&quot;</div>
            </>
          );
      }
    }, [form.method, form.id_like, form.password, form.phone, form.vcode, t, on_ccode_change, phone, ccode, set_form]);

    useEffect(() => {
      on_change?.(form);
    }, [form, on_change]);

    useEffect(() => {
      if (merge && !isEqual(merge, merge_prev)) {
        set_form({ ...form, ...merge });
      }
    }, [form, merge, merge_prev, set_form]);

    useEffect(() => {
      if (!merge?.method && !form.method) {
        set_form((s) => {
          s.method = N_login_method.id_like;
        });
      }
    }, [form.method, merge?.method, set_form]);

    return (
      <form onSubmit={submit} className={twMerge('c gap-3', className)} data-testid="Form_login" {...rest}>
        {!hide_heading && (
          <h1 className="r items-center gap-2">
            <LoginIcon /> {text_heading ?? t('common:login')}
          </h1>
        )}
        {signer && (
          <div>
            <div className="tile help cap" role="alert">
              <span className="mr-2">{t('auth:already_logged_in')}</span>
              <Link href="/dashboard/my/account" className="inline-block capitalize">
                {t('auth:manage_current_account')}: <span>@{signer.unique}</span>
              </Link>
            </div>
          </div>
        )}

        {/*<div className="px-3 mt-2">*/}
        {/*  <Passport_link_group />*/}
        {/*</div>*/}

        <div>
          <FormControl>
            <RadioGroup
              value={form.method}
              onChange={(e) => {
                set_form((s) => {
                  s.method = e.target.value;
                });
              }}
            >
              {methods.map((it) => (
                <FormControlLabel
                  sx={{ textTransform: 'capitalize' }}
                  key={it}
                  value={it}
                  control={<Radio />}
                  label={t(`auth:login_with_${it}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="c gap-2">
          {part()}
          {form.method === N_login_method.los && <Signup_agreement />}
          <div className="r items-center gap-2">
            <div>
              <Btn disabled={pending} type="submit" data-testid="main_form_submit">
                {text_submit ?? t('common:login')} {pending && <Icon_loading />}
              </Btn>
            </div>
            <Link href="/auth/signup" className="capitalize">
              {t('common:signup')}
            </Link>
            <Link href="/auth/recover" className="capitalize">
              {t('auth:forgot_password')}
            </Link>
          </div>
        </div>
      </form>
    );
  },
);

export default Form_login;
