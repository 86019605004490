import { T_ccode } from '@/declaration/type/t_ccode';
import { T_ccode_map } from '@/declaration/type/t_ccode_map';
import { Api } from './$api';
import { service } from './service';

const name = 'utility';

export class Utility extends Api {
  name = name;
  cache: {
    ccode: T_ccode_map;
  } = {
    ccode: {},
  };

  async load_ccode(): Promise<T_ccode_map> {
    if (!this.cache.ccode.length) {
      this.cache.ccode = (await this.get_ccode()) as T_ccode_map;
    }

    return this.cache.ccode;
  }

  async get_ccode(opt?: { key_by: string }): Promise<T_ccode[] | T_ccode_map> {
    return this.call(`${this.name}/list_ccode`, { key_by: 'ccode', ...opt });
  }
}

export const $utility = service<Utility>(Utility, name);
