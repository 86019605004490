import React, { FC, HTMLAttributes, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Tile extends HTMLAttributes<HTMLDivElement> {}

const Tile: FC<I_Tile> = memo<I_Tile>((props: I_Tile) => {
  const { style, className, children, ...rest } = props;

  return (
    <div {...rest} style={style} className={twMerge('rounded bg-foreground/5 px-3 py-2', className)}>
      {children}
    </div>
  );
});

export default Tile;
