import Img from '@/component/image/img';
import { T_ccode } from '@/declaration/type/t_ccode';
import { T_ccode_map } from '@/declaration/type/t_ccode_map';
import { env } from '@/env/env';
import { $utility } from '@/service/$utility';
import { load_geo, T_geo } from '@/utility/geo/geo';
import { Autocomplete, Box, TextField } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import values from 'lodash/values';
import { useTranslation } from 'next-i18next';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';

interface I_Ccode_selector {
  /**
   * Default ccode
   */
  def?: string;
  label?: string;
  hide_flag?: boolean;
  value?: T_ccode | string;

  on_change(v: T_ccode | undefined): void;
}

const sx = {
  '& > img': {
    mr: 2,
    flexShrink: 0,
  },
};

const Ccode_selector: FC<I_Ccode_selector> = memo<I_Ccode_selector>(({ on_change, def, value, label, hide_flag = true }) => {
  const { t } = useTranslation();
  const [map, set_map] = useState<T_ccode_map>({});
  const [v, set_v] = useState<T_ccode | undefined>({});
  const [input_value, set_input_value] = useState<string>('');
  const [geo, set_geo] = useState<T_geo>();

  label = label ?? (t('sender:ccode') as string);
  def = def || env.sender?.default_ccode;

  const render_input = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
          'data-testid': 'ccode_selector',
        }}
      />
    ),
    [label],
  );

  const opt_render = useCallback(
    (props: any, option: any) => (
      <Box component="li" sx={sx} data-testid={`ccode_option_${option.ccode}`} {...props}>
        {!hide_flag && (
          <Img
            loading="lazy"
            className="w-[20px]"
            src={`https://flagcdn.com/w20/${option.ccode?.toLowerCase()}.png`}
            alt={`Flag ${option.ccode}`}
          />
        )}
        {option.ccode} +{option.code}
      </Box>
    ),
    [hide_flag],
  );

  const option_label = useCallback(
    (option: any) => (option?.ccode ? `+${option?.code} (${option?.ccode})` : 'N/A'),
    [],
  );

  const on_change_input = useCallback((e: any, v: string) => {
    set_input_value(v);
  }, []);

  const _on_change = useCallback(
    (e: any, v: any) => {
      set_v(v);
      on_change(v);
    },
    [on_change],
  );

  const smart_load = useCallback(async () => {
    set_geo(await load_geo());
  }, []);

  useEffect(() => {
    $utility.load_ccode().then((r) => {
      set_map(r);
    });
  }, []);

  useEffect(() => {
    const ccode = def ?? geo?.ccode;
    if (ccode) {
      const v = map[ccode];
      if (v) {
        set_v(v);
        on_change(v);
      }
    }
  }, [map, geo, def, on_change]);

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        const v = map[value];
        if (v) {
          set_v(v);
        }
      } else {
        set_v(value);
      }
    } else {
      void smart_load();
    }
  }, [map, smart_load, value]);

  return (
    <>
      <Autocomplete
        options={values(map)}
        autoHighlight
        getOptionLabel={option_label}
        value={v}
        onChange={_on_change}
        inputValue={input_value}
        onInputChange={on_change_input}
        renderOption={opt_render}
        renderInput={render_input}
        className="_Ccode_selector _input_ccode"
      />
    </>
  );
});

export default Ccode_selector;
