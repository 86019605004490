const cache: {
  cloudflare?: T_geo_cloudflare;
  geo?: T_geo;
} = {};

export interface T_geo {
  ccode: string;
  ip: string;
  ua: string;
}

export interface T_geo_cloudflare {
  fl: string;
  h: string;
  ip: string;
  ts: string;
  visit_scheme: string;
  uag: string;
  colo: string;
  http: string;
  loc: string;
  tls: string;
  sni: string;
  warp: string;
  gateway: string;
}

export async function load_geo_cloudflare(refresh = false): Promise<T_geo_cloudflare> {
  if (!cache.cloudflare || refresh) {
    /*
     fl=446f96
     h=www.cloudflare.com
     ip=204.44.96.131
     ts=1639212559.191
     visit_scheme=https
     uag=Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.55 Safari/537.36
     colo=LAX
     http=http/2
     loc=US
     tls=TLSv1.3
     sni=plaintext
     warp=off
     gateway=off
     */
    const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const text = await res.text();
    const list: any = text.split('\n').map((it) => it.split('='));
    const r: T_geo_cloudflare = {} as any;
    for (const it of list) {
      // @ts-ignore
      r[it[0]] = it[1];
    }
    cache.cloudflare = r;
  }
  return cache.cloudflare;
}

export async function load_geo(refresh = false): Promise<T_geo> {
  if (!cache.geo || refresh) {
    const a = await load_geo_cloudflare();
    cache.geo = {
      ccode: a.loc,
      ip: a.ip,
      ua: a.uag,
    };
  }

  return cache.geo as T_geo;
}
