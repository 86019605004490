import Btn, { I_Btn } from '@/component/button/btn/btn';
import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import { I_vcode_send } from '@/declaration/api/type/i_vcode_send';
import { Vcode } from '@/declaration/rds/model';
import { N_type_vcode } from '@/declaration/rds/model/type/n_type_vcode';
import { N_lkey } from '@/declaration/type/n_lkey';
import { env } from '@/env/env';
import { useApi } from '@/hook/api/useApi';
import { radio } from '@/state/radio';
import { loading } from '@/utility/dynamic/loading';
import lget from '@/utility/storage/lget';
import lset from '@/utility/storage/lset';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { usePrevious, useToggle } from 'react-use';
import { useImmer } from 'use-immer';
import Modal_general from '../../modal/modal_general/modal_general';

export interface I_Vcode_button extends I_Btn {
  args: I_vcode_send;
  countdown?: number;
  text?: string;
  duration?: number;
  disabled?: boolean;
}

const Antibot_form_image = dynamic(() => import('@/component/antibot/antibot_form_image/antibot_form_image'), {
  loading,
});

const Vcode_button: FC<I_Vcode_button> = memo<I_Vcode_button>((props) => {
  let { args, countdown, text, duration, disabled } = props;
  const { t } = useTranslation();
  const args_prev = usePrevious(args);
  text = text ?? t('antibot:send').trim();
  duration = duration ?? env.antibot?.vcode_cd ?? 60;
  const [vis_throttle, vis_throttle_toggle] = useToggle(false);
  const [_args, set__args] = useImmer<I_vcode_send>(args);
  const [cd, set_cd] = useState(
    countdown || Math.round((lget([N_lkey.vcode_countdown, _args.purpose]) || 0) - Date.now() / 1000) || 0,
  );
  const { call: require_ip_throttle, pending: require_ip_throttle_pending } = useApi('vcode/require_ip_throttle');
  const { call: api_send, pending: api_send_pending } = useApi('vcode/send');
  const pending = require_ip_throttle_pending || api_send_pending;

  const send = useCallback(async (): Promise<void> => {
    const { ccode, phone, email } = _args;
    if (cd || ((!ccode || !phone) && !email)) {
      return;
    }

    set_cd(duration as number);

    try {
      await api_send(_args);
      radio.popup.next({ title: t('antibot:vcode_sent') });
      vis_throttle_toggle(false);
    } catch (e) {
      set_cd(0);
    }
  }, [_args, api_send, cd, duration, t, vis_throttle_toggle]);

  const handle_click = useCallback(async () => {
    const required = await require_ip_throttle({ type: _args.method });
    vis_throttle_toggle(required);
    if (!required) {
      await send();
    }
  }, [_args.method, require_ip_throttle, send, vis_throttle_toggle]);

  const should_disabled = useCallback((): boolean => {
    if (cd > 0 || !_args.purpose || !_args.method || pending) {
      return true;
    }
    switch (_args.method) {
      case N_type_vcode.email:
        if (!_args.email) {
          return true;
        }
        break;
      case N_type_vcode.sms:
        if (!_args.phone) {
          return true;
        }
        if (!_args.ccode) {
          return true;
        }
        break;
      case N_type_vcode.image:
        return true;
    }

    return false;
  }, [_args.ccode, _args.email, _args.method, _args.phone, _args.purpose, cd, pending]);

  useEffect(() => {
    let timer: any;

    if (cd > 0) {
      next_second();
    } else {
      timer && clearTimeout(timer);
      set_cd(0);
      cd_persist(null);
    }

    /**
     * @param till Disabled until future timestamp
     */
    function cd_persist(till: number | null): void {
      lset([N_lkey.vcode_countdown, _args.purpose], till);
    }

    function next_second(): void {
      timer = setTimeout(() => {
        const new_cd = cd - 1;
        cd_persist(Date.now() / 1000 + new_cd);
        set_cd(new_cd);
        clearTimeout(timer);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [_args.purpose, cd]);

  useEffect(() => {
    if (!Object.is(args_prev, args)) {
      set__args(args);
    }
  }, [args, args_prev, set__args]);

  return (
    <>
      <Btn disabled={should_disabled() || disabled} onClick={handle_click} {...props}>
        {cd ? cd + 's' : text} {pending && <Icon_loading />}
      </Btn>
      <Modal_general open={vis_throttle} onClose={vis_throttle_toggle}>
        <Antibot_form_image
          on_change={(code) => {
            set__args((s: any) => {
              s.dependency = s.dependency || ({} as any);
              (s.dependency as Vcode).code = code;
            });
          }}
          on_submit={send}
        />
      </Modal_general>
    </>
  );
});

export default Vcode_button;
